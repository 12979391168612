<template>
    <div class="login">

      <div class="seleLangs">
        <div class="imgList">
          <a href="https://chat.ichatlink.net/widget/standalone.html?eid=5296fa06a32dcc16a863e8931dfc4c95&amp;language=en" class="list-group-item list-group-item-action border-color" style="float: left;margin-right: 10px;">
            <div class="row">
              <div class="col align-self-center pl-0"><img src="../assets/chat.png" alt=""></div>
            </div>
          </a>
          <a href="/index/user/lang.html" class="list-group-item list-group-item-action border-color" style="float: left">
            <div class="row">
              <div class="col align-self-center pl-0"><img src="../assets/lang.png" alt="" @click="lang"></div>
            </div>
          </a>
        </div>
      </div>

        <div class="inputs">
            <div class="phone">
                <div class="name">{{please_enter}}</div>
                <input type="text" name="tel" @input="inname" />
            </div>
            <br>
            <div class="phone password">
                <div class="name">{{your_password}}</div>
                <input type="password" name="pwd" @input="inpass" autocomplete="off" />
            </div>
            
            <div class="loginBtn" @click="login">{{login_1}}</div>
        </div>

        <div class="tips">{{no_account}}<i><a style="color: #f24255;" href="javascript:;" @click="register">{{reg_title}}</a></i></div>


        <!-- <div class="face-but">
            <fc-bubb click>
                <fc-arr-btn @click="register">注册</fc-arr-btn>
            </fc-bubb>
        </div> -->
    </div>
</template>

<script>
export default {
    name: "Login",
    props: {
        msg: String,
    },
    data() {
        return {
            user: {
                tel: "",
                pwd: "",
            },
            reme: false,
            
            please_enter: "",
            your_password: "",
            login_1: "",
            no_account: "",
            reg_title: "",
        };
    },
    created() {
        // 初始化操作，如获取数据
        this.fetchData();
    },
    methods: {
        async fetchData() {
            var result = await this.$proxy.getvuelang();
            // result = JSON.parse(result);
            var info = result.info;
            this.please_enter = info.please_enter;
            this.your_password = info.your_password;
            this.login_1 = info.login_1;
            this.no_account = info.no_account;
            this.reg_title = info.reg_title;
        },
        // 获取用户名和密码
        inname(e) {
            this.user.tel = e.target.value;
        },
        inpass(e) {
            this.user.pwd = e.target.value;
        },
        // 跳转到注册
        register() {
            this.$router.push({ path: "/register" });
        },
        // 跳转到语言
        lang() {
            window.open('/index/user/lang','_self');
        },
        // 登录
        async login() {
            if (this.user.tel === "" || this.user.pwd === "") {
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons">error</span>',
                    color: "warn",
                    // title: "错误",
                    text: "Please enter account / password",
                });
                return false;
            }
            // 登录接口
            var result = await this.$proxy.login(this.user);
            // console.log(result)
            if (result.code === 0) {
                //存入token
                // localStorage.setItem('token',result.data['token']);
                // alert(localStorage.token);
                const loading = this.$vs.loading({
                    type: "circles",
                    color: "#d5397b",
                    text: "Login success",
                });
                this.hasOpenLoading = true;
                setTimeout(() => {
                    loading.close();
                    this.hasOpenLoading = false;
                }, 2000);
                window.open('/index/index/home','_self');
                // this.$router.replace("/index/index/home");
            } else {
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons">error</span>',
                    color: "warn",
                    // title: "错误",
                    text: result.info,
                });
            }
        },
    },
};
</script>


<style scoped>
.login {
    display: flex;
    justify-content: space-around;
    background: url("../assets/background.png") no-repeat;
    filter: opacity(80%);
    background-position: center center;
    background-size: cover;
    display: inline-block;
    width: 100%;
    min-height: 70vh;
    font-size: 1rem;
    color: #333;
}

input {
    background: none;
    border: none;
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding: 7px 0;
}

.face .face-input {
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    justify-content: center;
}

.face .face-input fc-input {
    margin: 1rem;
    --width: rem;
    --color: #41b883;
    --placeholder-color: #35495e;
    --circle-color: #32df72;

    background: none;
    border: none;
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding: 7px 0;
    margin-bottom: 20px;
}

.inputs {
    margin-top: 150px;
    border-radius: 50px;
    background-color: #fff;
    padding: 35px 0;
    opacity: 0.85;
    padding: 35px 25px;
    box-sizing: border-box;
}

.loginBtn {
    color: #fff;
    margin: 50px auto 0 auto;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 5px;
    background: linear-gradient(
        147deg,
        rgb(113, 183, 255),
        rgb(23, 131, 252) 74%
    );
}

.name {
    font-size: 14px;
    color: #000;
    font-weight: 700;
}

.login .tips {
    position: fixed;
    bottom: 50px;
    width: 100%;
    text-align: center;
    font-size: 14px;
}

.cont .tips i {
    font-style: inherit;
    color: rgb(101, 168, 247);
}

a {
    text-decoration: none;
    color: #333;
}

.seleLangs {
    position: fixed;
    width: 100%;
    right: 7px;
    z-index: 999;
    top: 10px;
}
.imgList {
    position: absolute;
    right: 17px;
    z-index: 999;
    top: 20px;
}
.imgList img {
    display: flex;
    width: 25px;
    height: 25px;
}


</style>
