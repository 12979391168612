<template>
  <div id="app">
    <div v-if="$route.meta.index === 1">
      <Nav></Nav>
      <Head></Head>
      <router-view></router-view>
    </div>
    <div v-if="$route.meta.index === 0">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
  },
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
#app {
  font-size: 1rem;
  color: #333;
}
</style>
